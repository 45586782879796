import React from 'react';
//import Layout from '../components/layout';
import { MDBContainer } from 'mdbreact';
import SEO from '../components/seo';
import Pageheader from '../Utils/pageheader';

const Guidelines = () => {
    return (
        <>
            <MDBContainer className="mt-4">
                <SEO title="Temple Guidelines | Hindu Temple Association of Iowa" />
                <h1 className="title font-weight-bolder text-primary">GUIDELINES </h1>
                <Pageheader />
                <h2 className="title font-weight-bold orange-text">Guidelines For Temple Facility or Services</h2>
                <p className=" text-justify">Our temple is a public use facility for use by those who support
                 the mission of the Temple and abide by its articles of incorporation and the By-Laws for social,
                 cultural, educational and religious purposes. Family events such as birthdays, weddings,
                 anniversaries etc. can be held here with prior permission and after providing detailed
                 information about the activities planned, from the Executive Committee. However, the Executive
                 Committee may reserve certain days of the year for specific religious festivals and
                 thus limit temple facility use during those days.You must agree to follow all guidelines
                 listed below and must fill all data as requested in facilities reservation form.
                 Please click this "Temple Facility Reservation Form" link to download, print and mail the form to address
                 shown in the form or email to contact@iowahindutemple.org.
                 </p>

                <h2 className="title font-weight-bold orange-text">Article of Incorporation and By-Laws</h2>
                <h3 className="title font-weight-bolder text-secondary">GENERAL GUIDELINES</h3>
                <ol>
                    <li className="lead text-justify">The heating and cooling system of the facility must not be violated or changed by anyone other than a committee member or members permitted by Executive Committee.</li>
                    <li className="lead text-justify">Only the trained persons should use the PA system.</li>
                    <li className="lead text-justify">Only the trained persons should use the PA system.</li>
                    <li className="lead text-justify">Shoes must be removed prior to entering the worship hall at all times.</li>
                    <li className="lead text-justify">No one is allowed to go on the altar area except the priest conducting the worship and volunteers helping priest.</li>
                    <li className="lead text-justify">When children's activities are planned, parents must accompany and supervise the event at all times and make sure that no child enters the altar area.</li>
                    <li className="lead text-justify">All activities shall be in keeping with the dignity and respect for the sanctity of a holy place and according to Hindu traditions.</li>
                    <li className="lead text-justify">No alcohol on the premises: no exceptions.</li>
                    <li className="lead text-justify">No Smoking and No Chewing Gum inside the facility.</li>
                    <li className="lead text-justify">All non-monetary items or donations require approval by the Temple executive committee prior to being placed in Temple premises. The approval can be obtained by writing to the chair person via email, contact@iowahindutemple.org.</li>
                    <li className="lead text-justify">As per the requirements of Sanatana Dharma ("the Hindu way of life"), the construction or changes to Temple structure, mode of worship, the quality requirements of the changes, the kind of images/deities to be installed, the materials from which they are to be made, their dimensions, proportions etc would be governed by ancient Sanskrit scriptures called Agamas ("a traditional doctrine, or system which commands faith"). The executive committee shall strictly follow these Dharmic traditions as detailed in consultation with priest when making any changes in or outside temple.</li>
                    <li className="lead text-justify">No item shall be taken, lent or removed from Temple premises by any individual. No personal or household items shall be left in Temple premises. All items left in Temple either unsolicited or requested/donated would be owned by the Temple permanently and could be used, moved, sold, disposed or discarded a month after item was left in the temple, at the discretion of executive committee.</li>
                    <li className="lead text-justify">Prasad could be served in prayer hall. However, all other foods or snacks must be served in the dinning hall.</li>
                    <li className="lead text-justify">No meat or non-vegetarian snacks or foods in the facility. No exceptions.</li>
                    <li className="lead text-justify">All users must assume the legal liability and responsibility of conducting the event. The board of trustees and the executive committee or the temple organization itself will not be responsible or liable for any injury to anyone at anytime at the facility.</li>
                    <li className="lead text-justify">The executive committee or subcommittee will be willing to offer any other service/help if requested.</li>
                    <li className="lead text-justify">Users must clean the premises after use and this includes the hall if used, adjoining rooms, if used, kitchen, dining hall and the foyer. Refrigerator, stove, oven, microwave oven, sinks, countertops, furniture to be properly cleaned and wiped.</li>
                    <li className="lead text-justify">Any trash or garbage generated during the activity must be properly disposed and deposited in the trash bins located at the southwest corner of the parking area.</li>
                    <li className="lead text-justify">Behaviors in and around the temple facility not conforming to the sanctity of Temple, the Hindu traditions and disturbing to the community at large will not be tolerated at any time. Group or persons engaging in such activity may be barred from using the facility for a period of one year or more at the discretion of the executive committee.</li>
                    <li className="lead text-justify">After performing special puja/ceremonies, do not leave any items (e.g. picture of deity, puja related materials, pots, pans, leftover food in the refrigerator etc.).</li>
                    <li className="lead text-justify">At any time, the temple premises should not have more than 200 people, including kids. The kitchen and dinning hall seating capacity is eighty (80). Therefore ensure not to exceed that capacity anytime.</li>
                    <li className="lead text-justify">No pets are allowed inside the prayer hall. No exceptions. Executive committee members would be happy to provide assistance to devotees with special needs.</li>
                    <li className="lead text-justify">All users must mute or switch off Mobile phones when in the prayer hall. Mobile phones could be used in dinning hall.</li>
                </ol>
                <h3 className="title font-weight-bolder text-secondary">RELIGIOUS EVENTS</h3>
                <p className="lead">Over and above the General Guidelines the following additional will apply to all Religious Events:</p>
                <ol>
                    <li className="lead">Reservation information : See reservation form.</li>
                    <li className="lead text-justify">The temple facility is available to the community for conducting religious programs and spiritually beneficial events which are open to community and are not fundraising events.</li>
                    <li className="lead text-justify">Groups or families must have prior written permission [at least one week in advance] from the program chair or a designated/co-coordinating person/committee or by the executive committee.</li>
                    <li className="lead text-justify">Permission request must be in writing and with details of the group and the planned activity addressed to program chair or to the executive committee.</li>
                    <li className="lead text-justify">Property liability is covered by temple insurance. Personal injury or liability is not covered by the temple insurance.</li>
                    <li className="lead text-justify">If the activity involves the presence of priest, the organizing family or group shall make separate arrangement with Temple priest in consultation with program chair. Only Temple employee priest can lead and perform all the religious activities in the Temple along with pre-approved priests or volunteers by the program chair.</li>
                    <li className="lead text-justify">If the event involves removal of chairs or stacking the chairs, the family or group using the hall is for responsible to remove, stack and keep chairs in original place after use.</li>
                    <li className="lead text-justify">The family or group using the facility will be responsible for managing the parking of the vehicles of their guests and will follow the rules and area allocated for this purpose. Parking on the grass is prohibited.</li>
                    <li className="lead text-justify">Additional parking for vehicles other than the temple parking lot is available at the neighboring pharmacy care Associates parking lot after 6 PM on weekdays and after 2 PM on Saturdays and all day on Sundays.</li>
                    <li className="lead text-justify">The committee, prior to the event, must approve use of the parking area for any other purpose in WRITING. This involves the establishment of an outdoor facility such as a tent or a cooking facility.</li>
                    <li className="lead text-justify">No loud music will be played outside the facility.</li>
                    <li className="lead text-justify">If the facility (property, furniture, PA system, chairs, refrigerator, stove, oven, microwave ovens, sinks, countertops) is not well cleaned or damaged after use, the family or group will be charged the cost of repairing the damage or the cleaning and/ or the cost of cleaning facility.</li>
                </ol>
                <h3 className="title font-weight-bolder text-secondary">YOUTH, CHILDREN'S EDUCATIONAL PROGRAMS & EVENTS</h3>
                <p className="lead text-justify">Over and above the General Guidelines the following additional Guide lines will apply to all Youth Children's Educational Programs & Events:</p>
                <ol>
                    <li className="lead text-justify">Reservation information : See reservation form.</li>
                    <li className="lead text-justify">The temple facility is available to the community for conducting educational and religious programs for children and youth by families and groups which is open to community which are not fundraising events.</li>
                    <li className="lead text-justify">Groups or families must have prior written permission [at least one week in advance] from the program chair or a designated/co-coordinating person/committee or by the executive committee.</li>
                    <li className="lead text-justify">In order to receive permission, complete the Temple Hall Reservation Form as posted on the web site and forward to program committee chair or to the executive committee members.</li>
                    <li className="lead text-justify">If educational activity involves fundraising activities of any kind, the teacher must follow the guidelines listed in the fundraising events.</li>
                    <li className="lead text-justify">Property liability will be covered by temple insurance. Personal injury liability is not covered by the temple insurance.</li>
                    <li className="lead text-justify">Parents shall supervise children at all times and make sure that children do not enter the altar area.</li>
                    <li className="lead text-justify">Committee will provide assistance in getting children's books and audio & visual aids if requested.</li>
                </ol>
                <h3 className="title font-weight-bolder text-secondary">SOCIAL EVENTS</h3>
                <p className="lead text-justify">Social events could be events, which have direct social benefit for a specific group. These could be a wedding, birthday party, anniversary etc. Such events will be governed by the following additional guidelines which are over and above the general Guide Lines:</p>
                <ol>
                    <li className="lead text-justify">Reservation information : See reservation form.</li>
                    <li className="lead text-justify">Deposit: $200 [refunded if the facility is found without any damage and area used has been cleaned thoroughly by the group as determined by the facility chair].</li>
                    <li className="lead text-justify">If the activity involves the presence of priest, the organizing family or group shall make separate arrangement with Temple priest in consultation with program chair. Only Temple employee priest can lead and perform all the religious activities in the Temple along with pre-approved priests or volunteers by the program chair.</li>
                    <li className="lead text-justify">If the event involves removal of chairs or stacking the chairs, the family or group using the hall is for responsible to remove, stack and keep chairs in original place after use.</li>
                    <li className="lead text-justify">The family or group using the facility will be responsible for managing the parking of the vehicles of their guests and will follow the rules and area allocated for this purpose. Not following these rules may lead to the vehicles being towed away at the owners expense. Parking on the grass is prohibited.</li>
                    <li className="lead text-justify">Additional parking for vehicles other than the temple parking lot is available at the neighboring pharmacy care Associates parking lot after 6 PM on weekdays and after 2 PM on Saturdays and all day on Sundays.</li>
                    <li className="lead text-justify">The committee, prior to the event, must approve use of the parking area for any other purpose in WRITING. This involves the establishment of an outdoor facility such as a tent or a cooking facility.</li>
                    <li className="lead text-justify">No inappropriate or loud music will be played in the hall or outside the facility.</li>
                    <li className="lead text-justify">The user must provide liability insurance for property damage and personal injury.</li>
                    <li className="lead text-justify">If the facility (property, furniture, PA system, chairs, refrigerator, stove, oven, microwave ovens, sinks, countertops) is not well cleaned or damaged after use, the family or group will be charged the cost of repairing the damage and the cost of cleaning the facility.</li>
                </ol>
                <h3 className="title font-weight-bolder text-secondary">FUND RAISING EVENTS (NON PROFIT ORGANIZATIONS AND COMMERCIAL)</h3>
                <p className="lead text-justify">Fund raising events could be those, which have direct social benefit for a specific group. These events could be a religious or a commercial event. The events should be governed by the following additional guidelines, which are over and above the general Guide Lines:</p>
                <ol>
                    <li className="lead text-justify">Reservation information : See reservation form.</li>
                    <li className="lead text-justify">Deposit: $200.00 [refunded if the facility is found without any damage and area used has been cleaned thoroughly by the group as determined by the facility chair].</li>
                    <li className="lead text-justify">If the activity involves the presence of priest, the organizing family or group shall make separate arrangement with Temple priest in consultation with program chair. Only Temple employee priest can lead and perform all the religious activities in the Temple along with pre-approved priests or volunteers by the program chair.</li>
                    <li className="lead text-justify">If the event involves removal of chairs or stacking the chairs, the family or group using the hall is for responsible to remove, stack and keep chairs in original place after use.</li>
                    <li className="lead text-justify">The family or group using the facility will be responsible for managing the parking of the vehicles of their guests and will follow the rules and area allocated for this purpose. Not following these rules may lead to the vehicles being towed away at the owners expense. Parking on the grass is prohibited.</li>
                    <li className="lead text-justify">Additional parking for vehicles other than the temple parking lot is available at the neighboring pharmacy care Associates parking lot after 6 PM on weekdays and after 2 PM on Saturdays and all day on Sundays.</li>
                    <li className="lead text-justify">The committee, prior to the event, must approve use of the parking area for any other purpose in WRITING. This involves the establishment of an outdoor facility such as a tent or a cooking facility.</li>
                    <li className="lead text-justify">No inappropriate or loud music will be played in the hall or outside the facility.</li>
                    <li className="lead text-justify">The user must provide liability insurance for property damage and personal injury.</li>
                    <li className="lead text-justify">If the facility (property, furniture, PA system, chairs, refrigerator, stove, oven, microwave ovens, sinks, countertops) is not well cleaned or damaged after use, the family or group will be charged the cost of repairing the damage and the cost of cleaning the facility.</li>
                </ol>
                <h3 className="title font-weight-bolder text-secondary">Guidelines for Collection and Dissemination of Information</h3>
                <ol>
                    <li className="lead text-justify">Temple shall collect first name, last name, email address, phone number and mailing address of devotees, donors and general body members.</li>
                    <li className="lead text-justify">The private data such as social security numbers, passport numbers or drivers license numbers of devotees, donors and general body members are NOT collected OR stored in any form including database or files or reports.</li>
                    <li className="lead text-justify">The information collected or stored in the database or files or reports shall be used for the official temple business only by Temple Executive Committee and shall not be shared or distributed to any individuals or organizations regardless of the permissions given by individuals whose data is collected or stored in the database or files or reports.</li>
                    <li className="lead text-justify">The information collected or stored in the database shall be used by Temple for communication to public about temple events or events associated to Temple or in the interest of Temple and it's community to advance the mission of the temple.</li>
                    <li className="lead text-justify">The Temple email or address list shall be used solely by the Temple EC Chairperson, PR and Secretary to send communications to email list members about Temple events either in Temple or outside Temple sponsored by Temple</li>
                    <li className="lead text-justify">The Temple email communications shall be limited to approximately 1 per day or maximum of 3 per week due to terms and conditions signed by Temple EC to service provider, Google Inc when the original email list was created.</li>
                    <li className="lead text-justify">The Temple email and address list shall NOT be used by the Temple EC members for the benefit of for-profit individuals or institutions or services.</li>
                    <li className="lead text-justify">The Temple email list could be used to make limited number of announcements on behalf of registered non-profit institutions. The events or programs must be beneficial to Temple and its community members. The Temple announcements would take precedence over the announcements of registered not-for-profit institutions.</li>
                    <li className="lead text-justify">The Temple email list could be used for the benefit of Temple and it's community members to notify cultural, spiritual or educational events or program organized by not-for-profit groups or individuals. The groups or individuals organizing the events must declare in writing that they would not profit monetarily from the events or programs for which announcements are made using Temple email list.</li>
                    <li className="lead text-justify">The Temple events or program announcements would take precedence over the announcements of not-for-profit institutions or groups or individuals.</li>
                </ol>
                <h3 className="title font-weight-bolder text-secondary ">Guidelines for recognition</h3>
                <ol>
                    <li className="lead text-justify">As stated in Article 10, Section 9 of the By-laws, any person providing exceptional services/contribution, financial or otherwise, to the Temple may be honored to become a Patron. A Patron shall be nominated by the Executive Committee and the Board of Trustees, and shall be confirmed by the General Body. There could be one or two Patrons designated as the Patron of the Year and the nomination is at the discretion of the Executive Committee and the Board of Trustees.</li>
                    <li className="lead text-justify">The Executive Committee shall from time to time or during major annual events, recognize any individual, family or an institution providing exceptional services/contribution, financial or otherwise, to the Temple and its devotees community. The recommendations for recognitions could be made by members in Executive Committee or Board of Trustees. However, the final recognition of the person, family or group is at the discretion of the Executive Committee.</li>
                    <li className="lead text-justify">The Executive Committee shall recognize recently passed on/away individual who provided exceptional services/contribution, financial or otherwise, to the Temple and its devotees community. The recommendations for memorial or recognitions could be made by members in Executive Committee, Board of Trustees or members of General Body. However, the memorial or recognition of the deceased person is at the discretion of the Executive Committee.</li>
                    <li className="lead text-justify">The Executive Committee shall not provide any financial incentives or payments to any persons or groups within or outside the community for providing services on voluntary basis such as, during Satsang, workshops, demonstrations, lectures, religious or cultural programs or during visits or meetings. Any recognition of such volunteer service or contributions of individuals or groups shall be nominal and financial incentive or payments shall not exceed $50.</li>
                </ol>
                <h4 className="title font-weight-bolder text-danger">Yours Sincerely, <br /> Executive Committee</h4>
            </MDBContainer>

        </>
    );
};

export default Guidelines;